
$brand-primary: #3399cc !default;
$theme-primary: #3399cc;
$theme-secondary: #1a446f;



@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');
@import url('https://fonts.googleapis.com/css?family=Raleway:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');


$font-family-sans-serif:  'Raleway', sans-serif;
$font-family-serif: 'Raleway', serif !default;

$text-font:  'Raleway', serif;
$title-font: 'Raleway', serif;
$button-font: 'Raleway',serif;


$main-text: #413732; // main text
$link: #3399cc; // anchor tags
$background: #f5f5f5; // body background color



$color-1: #3399cc; // 
$color-2: #1a446f; // 
$color-3: #0f263e; // 
$color-4: #fff; // blanco
$color-5: #a3a09a; // gris claro


$black: #58595B;
$white: #fff;
$bg: #f3f3f3;
$title: #28ADBE;


// bootstrap
$brand-primary: $color-1!default;
$theme-primary: $color-1;


// fonts 

$primary-font: 'Raleway', serif;
$secondary-font: 'Raleway', serif;




@mixin padding-left() {
    padding-left: 20px;
    @media(min-width: $screen-sm-min) {
        padding-left: 40px;
    }
    @media(min-width: $screen-md-min) {
        padding-left: 80px;
    }
}


   
$S:     480px;   
$SM:    768px;  
$M:     992px;     
$L:     1200px;     
$XL:     1800px;  

// media queries

@mixin MQ($canvas) {
  @if $canvas == S {
   @media only screen and (min-width: $S) { @content; } 
  }
  @if $canvas == SM {
   @media only screen and (min-width: $SM) { @content; } 
  }
  @else if $canvas == M {
   @media only screen and (min-width: $M) { @content; } 
  }
  @else if $canvas == L {
   @media only screen and (min-width: $L) { @content; } 
  }
  @else if $canvas == XL {
   @media only screen and (min-width: $XL) { @content; } 
  }
}




