// Core variables and mixins
@import "variables";
@import "bootstrap";
@import "mixins";
@import "fonts";
@import "core";
@import "plugins";
// https://fontawesome.bootstrapcheatsheets.com/
@import "font-awesome/font-awesome";

body {
    background-color: $color-4;
}

.wpml-ls-statics-shortcode_actions {
    display: none !important;
}

/*= input focus effects css
=========================== */
:focus {
    outline: none;
}

::-moz-selection {
    background: #1071CA;
    color: $color-4;
    text-shadow: none;
}

::selection {
    background: #1071CA;
    color: $color-4;
    text-shadow: none;
}

section {
    width: 100%;
    float: left;
}

// logos
$logo: '../img/logo.png';

%logo-height {
    height: 60px;

    @include MQ(L) {
        height: 80px;
    }

    a {
        height: 20px;

        @include MQ(L) {
            height: 30px;
        }

        @include MQ(L) {
            //height: 80px;
        }
    }
}

%logo-height-shrink {
    height: 60px;

    @include MQ(L) {
        height: 80px;
    }

    a {
        height: 20px;

        @include MQ(L) {
            height: 30px;
        }
    }
}

%logo-size {
    height: 20px;
    width: 220px;
    background-size: auto 20px;

    @include MQ(L) {
        height: 30px;
        width: 330px;
        background-size: auto 30px;
    }

    @include MQ(L) {
        //height: 80px;
        //width: 250px;
        //background-size: auto 80px;
    }
}

%logo-size-shrink {
    height: 20px;
    width: 220px;
    background-size: auto 20px;

    @include MQ(L) {
        height: 30px;
        width: 330px;
        background-size: auto 30px;
    }
}

// Nav
.auto-hide-header {
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    @include clearfix;
    background-color: $color-4;

    .top-space {
        width: 100%;
        float: left;
        height: 30px;
        text-align: right;
        position: relative;
        z-index: 9999;
        background-color: #1a446f;

        ul {
            display: inline-block;
            float: right;
            padding: 0;
            margin: 0;
            list-style: none;
            @include font-size(12px);

            @include MQ(L) {
                @include font-size(14px);
            }

            li {
                display: inline-block;
                float: left;
                line-height: 30px;
                height: 30px;
                padding: 0;

                a {
                    line-height: 30px;
                    padding: 0 5px;
                    color: $color-4;
                    font-weight: bold;

                    &:hover,
                    &:focus {
                        color: $color-4;
                        text-decoration: none;
                    }
                }
            }

            &.social {
                margin-right: 0;

                li {
                    a {
                        font-size: 22px;

                        span {
                            line-height: 30px;
                        }
                    }
                }
            }

            &.pages {}

            &.languages {
                margin-right: 0 !important;
                float: left;
                color: $color-4;
                li {
                    a {
                        border-right: 1px solid $color-4;
                        color: $color-4;

                        &:hover,
                        &:focus {
                            text-decoration: none;
                            color: $link;
                        }

                        &.active {
                            font-weight: bold;
                        }
                    }
                }

                li:last-child a {
                    padding-right: 0;
                    border-right: 0;
                }
            }

            &.contact-info {
                li {
                    a {
                        padding: 0 5px;
                        display: block;
                        line-height: 30px;

                        @include MQ(S) {
                            padding: 0 15px;
                        }

                        &:hover,
                        &:focus {
                            text-decoration: none;
                            color: $color-4;
                            background-color: $color-3;
                        }
                    }
                }
            }
        }

        @include MQ(L) {
            display: block;
        }
    }

    .top-menu {
        width: 100%;
        float: left;
        height: auto;

        @include MQ(L) {
            height: 120px;
        }
    }

    .container {
        position: relative;
    }

    .logo {
        float: left;
        display: inline-block;
        margin: 0;
        @extend %logo-height;
        @include flexbox;
        @include align-items($value: center);

        a {
            @include MQ(SM) {
                display: inline-block;
                width: auto;
            }
        }

        @include MQ(L) {
            margin-top: 20px;
        }

        .logo-img {
            background-image: url($logo);
            background-position: 0 0;
            display: inline-block;
            background-repeat: no-repeat;
            text-indent: -9999px;
            @extend %logo-size;
        }
    }

    .primary-nav {
        display: inline-block;
        float: right;
        height: 100%;
        padding-right: 0;
        width: 100%;

        @include MQ(L) {
            height: 120px;
            margin: 0;
            width: auto;
        }

        .nav-trigger {
            position: absolute;
            top: 12px;
            display: table;
            height: auto;
            padding: 0 1em;
            @include font-size(10px);
            text-transform: uppercase;
            color: $link;
            font-weight: bold;
            right: 5px;

            //border-left: 1px solid shade($link, 5%);
            &:hover,
            &:focus,
            &.active {
                text-decoration: none;
            }

            span {
                /* vertically align inside parent element */
                display: table-cell;
                vertical-align: middle;
            }

            em,
            em::after,
            em::before {
                /* this is the menu icon */
                display: block;
                position: relative;
                height: 2px;
                width: 22px;
                background-color: $link;
                backface-visibility: hidden;
            }

            em {
                /* this is the menu central line */
                margin: 10px auto;
                transition: background-color .2s;
                background-color: rgba($color-3, 0);
            }

            em::before,
            em::after {
                position: absolute;
                content: '';
                left: 0;
                transition: transform .2s;
            }

            em::before {
                /* this is the menu icon top line */
                transform: rotate(-45deg);
                @include rotate(-45deg);
            }

            em::after {
                /* this is the menu icon bottom line */
                transform: rotate(45deg);
                @include rotate(45deg);
            }

            @include MQ(L) {
                display: none;
            }
        }

        .nav-trigger.collapsed {
            em {
                /* transform menu icon into a 'X' icon */
                background-color: $link;
            }

            em::before {
                /* rotate top line */
                transform: translateY(-6px);
                @include translateY(-6px);
            }

            em::after {
                /* rotate bottom line */
                transform: translateY(6px);
                @include translateY(6px);
            }
        }

        >ul {
            z-index: 2;
            padding: 15px;
            background-color: $color-4;
            margin: 0 -15px;
            font-family: $secondary-font;
            //display: none;
            list-style: none;

            li {
                padding: 0;

                &.active a,
                &.current-menu-ancestor a {
                    color: $link;
                }
            }

            a {
                display: block;
                color: #000;
                @include font-size(18px);
                text-transform: uppercase;
                font-weight: 500;
                padding: 3px 0;

                &:hover,
                &:focus {
                    color: $link;
                    text-decoration: none;
                }
            }
        }

        @include MQ(L) {
            /* vertically align its content */
            display: table;

            >ul {
                /* vertically align inside parent element */
                display: table-cell;
                vertical-align: middle;
                /* reset mobile style */
                position: relative;
                width: auto;
                right: 0;
                margin: 0;
                padding: 0;
                background-color: transparent;
                @include clearfix;

                li {
                    display: inline-block;
                    float: left;
                    padding-right: 0;

                    &:last-of-type {
                        margin-right: 0;
                        padding-right: 0;
                        border-right: 0;
                    }
                }

                a {
                    padding: 0 0 0 15px;
                    border: none;
                    line-height: 1;
                    @include font-size(16px);
                    line-height: 120px;
                }
            }
        }
    }

    &.shrink {
        background: $color-4;
        position: fixed;

        .top-space {
            display: none;
        }

        .top-menu {
            @include MQ(L) {
                height: 80px;
            }
        }

        .logo {
            @extend %logo-height-shrink;
            margin-top: 0;

            .logo-img {
                background-image: url($logo);
                background-position: 0 0;
                display: inline-block;
                background-repeat: no-repeat;
                @extend %logo-size-shrink;
            }
        }

        .primary-nav {
            margin-top: 0;

            @include MQ(L) {
                height: 80px;
            }

            >ul {
                li {
                    a {
                        @include MQ(L) {
                            line-height: 80px;
                        }
                    }
                }
            }
        }
    }
    .dropdown-menu {
        position: relative;
        border: 0;
        border-radius: 0;
        float: none;
        -webkit-box-shadow: none;
        box-shadow: none;

        @include MQ(L) {
            float: left;
            margin: 0;
            padding: 0;
            position: absolute;
            -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        }

        li {
            width: 100%;

            a {
                padding: 5px 5px;
                line-height: 1.2 !important;
                color: $link;
                text-transform: none;
                font-weight: bold;

                @include MQ(L) {
                    padding: 10px 15px;
                }

                &:hover,
                &:focus {
                    background-color: $link !important;
                    color: $color-4 !important;
                }
            }

            &.active a {
                background-color: $link !important;
                color: $color-4 !important;
            }
        }
    }
}

section {
    width: 100%;
    float: left;
}

h3 {}

.featured {
    color: $link;

    a {
        text-decoration: underline;

        &:hover,
        &:focus {
            color: #000;
        }
    }
}

.fons {
    font-size: 20px;
    color: $color-4;

    @include MQ(M) {
        font-size: 30px;
    }
}

.text-white {
    color: #fff;
}

.vc_empty_space {
    width: 100%;
    float: left;
}

// Slide principal
.slide-principal {
    @include clearfix;
    margin-top: 0;

    .slide {
        float: left;
        width: 100%;
        background-size: cover;
        background-position: center center;
        color: $color-4;
        background-color: #eee;
    }

    .cover {
        float: left;
        width: 100%;
        //background: rgba(0, 0, 0, 0.3);
        position: relative;
        @include linear-gradient-b(top, rgba($color-1, 1) 0%, rgba($color-1, 0.3) 30%, rgba($color-1, 0.2) 70%, rgba($color-1, 0) 100%);
    }

    .container {}

    .slide-text {
        text-align: center;
        width: 100%;
        min-height: 300px;
        display: flex;
        align-content: center;
        @include flexbox;
        @include flex-wrap(wrap);
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(center);

        @media (min-width: $screen-sm-min) {
            min-height: 400px;
        }

        .content-text {
            @media (min-width: $screen-sm-min) {}

            @media (min-width:$screen-md-min) {}

            img {
                width: 80%;
                height: auto;

                @media (min-width: $screen-sm-min) {
                    margin-bottom: 15px;
                }
            }
        }

        h2 {
            margin: 15px 0;
            font-size: 22px;
            text-transform: uppercase;

            @media (min-width: $screen-md-min) {
                font-size: 32px;
            }

            @media (min-width:$screen-lg-min) {
                font-size: 40px;
            }

            em {
                display: block;
            }
        }

        .btn {
            margin: 15px auto 15px auto;
            text-transform: uppercase;
        }
    }

    .owl-dots {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .owl-carousel .owl-nav .owl-prev {
        left: -20px;

        @include MQ(M) {
            left: -50px;
        }

        i {
            padding-right: 5px;

            @media(min-width: $screen-sm-min) {
                padding-right: 5px;
            }
        }
    }

    .owl-carousel .owl-nav .owl-next {
        right: -20px;

        @include MQ(M) {
            right: -50px;
        }

        i {
            padding-left: 5px;

            @media(min-width: $screen-sm-min) {
                padding-left: 5px;
            }
        }
    }

    .owl-nav .owl-prev {
        left: 20px;

        @include MQ(M) {
            left: 50px;
        }
    }

    .owl-nav .owl-next {
        right: 20px;

        @include MQ(M) {
            right: 50px;
        }
    }

    .custom-icon-left {
        background-image: url('../img/e-prev.png');
    }

    .custom-icon-right {
        background-image: url('../img/e-next.png');
    }
}

.header-especialidades {
    @extend .slide-principal
}

// Section home
.section-home {
    margin: 0;
    width: 100% !important;
    float: left;
    overflow: hidden;
    height: 0;
    min-height: 0;
    padding-bottom: 70%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    @include MQ(S) {
        padding-bottom: 56.6%;
        margin: 0;
    }

    .video-full {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: auto;
        height: 100%;
    }
}

// Marign
.margin-video {
    margin: -40px 0 0 0;

    @include MQ(S) {
        margin: -90px 0 0 0;
    }
}

// Contingut
.contingut {
    background-color: #f5f5f5;

    h3 {
        strong {
            color: $link;
        }
    }
}

// Promocions
.promocions {
    margin-bottom: 60px;

    .promo {
        margin-bottom: 0 !important;
        //padding: 0 40px !important;
    }

    .owl-stage {
        display: flex;
        align-items: stretch !important;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .owl-item {
        display: flex;
    }

    .owl-dots {
        bottom: -60px;
    }
}

.promos {}

.promo {
    margin-bottom: 50px;
    padding: 0;
    background: #fff;
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .image {
        width: 100%;

        @include MQ(M) {
            width: 50%;
        }

        .cover {
            width: 100%;
            height: 0;
            min-height: 0;
            padding-bottom: 56.2%;
            background-size: cover;
            background-position: center center;
            position: relative;
            background-color: #eee;

            @include MQ(M) {
                padding-bottom: 75%;
            }

            @include MQ(L) {
                padding-bottom: 76%;
            }
        }
    }

    .text {
        width: 100%;
        padding: 30px;
        position: relative;
        text-align: left;

        @include MQ(M) {
            width: 50%;
            padding: 30px;
        }

        .fecha {
            font-size: 14px;
            color: #999999;
        }

        h4 {
            font-size: 20px;
            font-weight: bold;
            margin: 0 0 15px 0;
        }

        .precio {
            margin: 0 0 25px 0;
            font-weight: bold;
            font-size: 40px;
        }
    }
}

.especialidades-slide {
    margin-bottom: 60px;
    width: 100%;
    float: left;

    &:after {
        content: '';
        display: block;
    }

    .owl-stage {
        display: flex;
        align-items: stretch !important;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .owl-item {
        display: flex;
    }

    .owl-dots {
        bottom: -60px;
    }

    .owl-dots .owl-dot span {
        background: #eee;
    }

    .owl-nav [class*='owl-'] {
        background: #f5f5f5;
        color: #fff;
    }

    .owl-nav [class*='owl-']:hover {
        background: #eee;
        color: #fff;
    }

    .custom-icon-left {
        background-image: url('../img/prev.png');
    }

    .custom-icon-right {
        background-image: url('../img/next.png');
    }
}

.especialidad {
    width: 100%;
    margin: 0;
    float: left;
    clear: both;

    .image {
        width: 100%;

        .cover {
            width: 100%;
            height: 0;
            min-height: 0;
            padding-bottom: 56.2%;
            background-size: cover;
            background-position: center center;
            position: relative;
            background-color: #eee;

            @include MQ(M) {
                padding-bottom: 75%;
            }

            @include MQ(L) {
                padding-bottom: 56.2%;
            }
        }
    }

    .content {
        background-color: $link;
        color: #fff;
        padding: 10px 20px;

        .title {
            margin: 0;
            padding: 0;
            font-size: 1rem;
            text-transform: none;

            a {
                color: #fff;
            }
        }
    }
}

// Noticias
// Last news
.last-news {
    margin: 0;
    clear: both;

    .owl-stage {
        @include flexbox;
        @include flex-direction($value: row);
        @include align-items($value: stretch);
        padding: 5px 0;
    }

    .post {
        text-align: center;
        margin: 0;
        height: 100%;
        background-color: $color-4;

        a {}

        .image {
            height: 0;
            min-height: 0;
            padding-bottom: 56.25%;
            position: relative;
            overflow: hidden;
            width: 100%;
            float: left;
            background-color: #f9f9f9;
        }

        .fecha {
            color: #999999;
            margin: 0;
            font-family: $title-font;
            font-size: 14px;
            @include transition(all linear .1s);
        }

        .cover {
            height: 0;
            min-height: 0;
            padding-bottom: 56.25%;
            background-position: center center;
            background-size: cover;
            position: relative;
            overflow: hidden;
            @include transition(all linear .2s);
        }

        .text {
            width: 100%;
            float: left;
            //min-height: 175px;
            height: 100%;
            padding: 10px 0 30px 0;
            background: $color-4;
            color: $black;
            text-align: left;
            @include transition(all linear .2s);

            h3 {
                margin: 10px auto 10px auto;
                color: #000;
                font-weight: bold;
                @include transition(all linear 0s);
                font-size: 20px;

                @include MQ(L) {
                    font-size: 20px;
                }
            }
        }
    }
}

.title {
    text-transform: uppercase;
}

.archive-blog {
    margin: 0 -15px 50px -15px;
    clear: both;
    @include flexbox;
    @include flex-wrap($value: wrap);
    @include align-items($value: stretch);
    @include align-content($value: flex-start);
    @include justify-content($value: flex-start);

    .post-cover {
        width: 100%;
        padding: 15px 15px 35px 15px;
        position: relative;
    }

    .post {
        text-align: center;
        margin: 0;
        background-color: $color-4;
        position: relative;
        @include flexbox;
        @include flex-wrap($value: wrap);
        @include align-items($value: flex-start);
        @include align-content($value: center);
        @include justify-content($value: flex-start);

        .image {
            width: 100%;

            @include MQ(SM) {
                width: 50%;
            }
        }

        .fecha {
            font-size: 14px;
            color: #999999;
        }

        .cover {
            width: 100%;
            height: 0;
            min-height: 0;
            padding-bottom: 56.25%;
            background-position: center center;
            background-size: cover;
            position: relative;
            overflow: hidden;
            @include transition(all linear .2s);
        }

        .text {
            width: 100%;
            float: left;
            height: 100%;
            padding: 10px 15px 30px 15px;
            background: $color-4;
            color: $black;
            text-align: left;
            @include transition(all linear .2s);

            @include MQ(SM) {
                width: 50%;
                padding: 0 30px;
            }

            h4 {
                font-weight: 20px;
                font-weight: bold;
                margin: 0 0 15px 0;

                a {
                    color: #000;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                        color: $link;
                    }
                }
            }
        }
    }
}

// Team
.team {
    margin: 0 -15px 15px -15px;
    padding: 0;
    @include flexbox;
    @include flex-wrap($value: wrap);
    @include align-items($value: stretch);
    @include align-content($value: flex-start);
    @include justify-content($value: flex-start);

    .persona-cover {
        width: 100%;
        padding: 15px;
        position: relative;

        @include MQ(SM) {
            width: 50%;
        }
    }

    .persona {
        margin: 0;
        width: 100%;
        float: left;
        height: 100%;
        text-align: left;
        font-size: 14px;
        background-color: #f5f5f5;
        position: relative;
        @include flex-wrap($value: wrap);
        @include align-items($value: stretch);
        @include align-content($value: flex-start);
        @include justify-content($value: flex-start);

        .image {
            position: relative;
            overflow: hidden;
            width: 100%;
            float: left;
            background-color: #f9f9f9;

            @include MQ(SM) {
                width: 40%;
            }
        }

        .cover {
            height: 0;
            min-height: 0;
            padding-bottom: 150%;
            background-position: center center;
            background-size: cover;
            position: relative;
            overflow: hidden;
            @include transition(all linear .2s);
        }

        .text {
            width: 100%;
            float: left;
            padding: 15px 15px 15px 15px;

            @include MQ(SM) {
                width: 60%;
            }

            h3 {
                text-transform: uppercase;
                font-size: 16px;
                margin: 5px 0;
            }

            p {
                margin: 0;
            }
        }
    }
}

// Team
.team-auxiliars {
    margin: 0 -15px 15px -15px;
    padding: 0;
    @include flexbox;
    @include flex-wrap($value: wrap);
    @include align-items($value: stretch);
    @include align-content($value: flex-start);
    @include justify-content($value: flex-start);

    .persona-cover {
        width: 100%;
        padding: 15px;
        position: relative;

        @include MQ(SM) {
            width: 25%;
        }

        @include MQ(MD) {
            width: 25%;
        }
    }

    .persona {
        margin: 0;
        width: 100%;
        float: left;
        height: 100%;
        text-align: left;
        font-size: 14px;
        background-color: #fff;
        position: relative;

        .image {
            position: relative;
            overflow: hidden;
            width: 100%;
            float: left;
            background-color: #f9f9f9;
        }

        .cover {
            height: 0;
            min-height: 0;
            padding-bottom: 150%;
            background-position: center center;
            background-size: cover;
            position: relative;
            overflow: hidden;
            @include transition(all linear .2s);
        }

        .text {
            width: 100%;
            float: left;
            padding: 15px 0;

            h3 {
                text-transform: uppercase;
                font-size: 16px;
                margin: 5px 0;
            }

            p {
                margin: 0;
            }
        }
    }
}

// Especialidades
.faqs {
    background-color: $link;
    color: $color-4;

    .vc_toggle_size_md.vc_toggle_arrow .vc_toggle_icon {
        float: right;
    }
}

.view-all {
    width: 100%;
    margin: 60px auto 30px auto;
    text-align: center;

    .btn {

        &:hover,
        &:focus {}
    }
}

// Twitter
.logo-twitter {
    img {
        max-width: 70px !important;
        height: auto !important;
    }
}

#ctf.ctf-super-narrow .ctf-more span {
    margin-top: 0 !important;
}

.gris {
    background-color: $background;
}

.franja {
    border-top: 15px solid #ed7b0b;
}

// margin header
.header-image-img {
    height: 200px;
    background-size: cover;
    background-position: center center;
}

.header-image {
    height: 200px;
    background: rgb(40, 173, 190);
    background: -moz-linear-gradient(left, rgba(40, 173, 190, 1) 0%, rgba(33, 92, 184, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(40, 173, 190, 1) 0%, rgba(33, 92, 184, 1) 100%);
    background: linear-gradient(to right, rgba(40, 173, 190, 1) 0%, rgba(33, 92, 184, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#28adbe', endColorstr='#215cb8', GradientType=1);
}

// titol gran
.titol-gran {
    margin: 30px 0;
    padding: 20px 0;
    @include font-size(50px);
    position: relative;
    color: $link;
    display: inline-block;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 120px;
        height: 2px;
        background-color: $link;
    }
}

%border {
    border-top: 2px solid $link;
    padding-top: 15px;
}

// text-destacat
.text-destacat {
    margin: 40px 0;
    @extend %border;
}

// titol-destacat
.espai-title {
    width: 100%;
    float: left;
    text-align: left;
}

.titol-destacat {
    margin: 20px 0;
    padding: 20px 0;
    @include font-size(30px);
    position: relative;
    color: $link;
    font-weight: 900;
    display: inline-block;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 120px;
        height: 5px;
        background-color: $link;
    }
}

.featured-section {
    width: 100%;
    float: left;
    margin: 15px 0 30px 0;
}

.featured-service {
    width: 100%;
    float: left;
    margin: 15px 0;
    height: 100%;

    //@include flexbox;
    //@include flex-wrap($value: wrap);
    //@include align-items($value: stretch);
    //@include align-content($value: flex-start);
    //@include justify-content($value: flex-start);
    //background-color: $color-4;
    h3 {
        margin: 0;
        color: $link;
        padding: 30px 15px 15px 15px;
        font-weight: 900;
        font-size: 24px;
        width: 100%;

        //background-color: #f5f5f5;
        @include MQ(M) {
            font-size: 30px;
        }
    }

    .content-service {
        padding: 15px;
        background-color: $color-4;
        height: 100%;
        width: 100%;
        min-height: 170px;
    }
}

.iguals {
    .wpb_wrapper {
        //margin: 15px 0;
        //background-color: $color-4;
        //height: 100%;
    }
}

//fitxa-serveis
.fitxa-serveis {
    display: table;
    margin: 15px 0;
    width: 100%;
    @extend %border;

    a {
        cursor: pointer;
        margin: 0;
        padding: 0;
        display: block;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    .imatge {
        display: table-cell;
        vertical-align: middle;
        padding-right: 15px;
        width: 100px;

        @include MQ(SM) {
            display: block;
            padding-bottom: 10px;
            text-align: center;
        }

        @include MQ(M) {
            display: table-cell;
            vertical-align: middle;
            text-align: left;
        }

        img {
            width: 100px;
            height: auto;
            @include transition(all linear .2s);
        }
    }

    .texte {
        display: table-cell;
        vertical-align: middle;
        color: $link;
        font-weight: bold;
        @include font-size(16px);
        line-height: 1.2;
        @include transition(all linear .2s);

        @include MQ(SM) {
            display: block;
            text-align: center;
        }

        @include MQ(M) {
            display: table-cell;
            vertical-align: middle;
            text-align: right;
            @include font-size(18px);
        }

        @include MQ(L) {
            @include font-size(20px);
        }
    }

    &:hover {
        .texte {
            color: #7c7c7c;
        }

        .imatge img {
            //@include transform(scale(1.2));
        }
    }
}

// logoss-add
.logos {
    //margin: 15px -15px;
    padding: 0;

    //@include flexbox;
    //@include flex-wrap($value: wrap);
    //@include align-items($value: center);
    //@include align-content($value: center);
    //@include justify-content($value: center);
    .logo {
        margin: 0;
        width: 100%;
        padding: 15px;
        height: auto;
        text-align: center;

        a {

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        img {
            max-height: 100px;
            width: auto;
            margin: 0 auto;
        }
    }
}

// Section title
.breadcrumb {
    padding: 8px 15px;
    margin: 0;
    list-style: none;
    background-color: transparent;
    border-radius: 0;
    font-size: 10px;

    @include MQ(SM) {
        font-size: 14px;
    }
}

.breadcrumb>.active {
    color: #eee;
}

.section-title-breadcrumb {
    display: table;
    width: 100%;
    color: $color-4;
    margin-bottom: 15px;
    overflow-y: hidden;
    background-size: cover;
    background-position: center center;

    .cover {
        padding-top: 100px;
        @include linear-gradient-b(left, rgba($color-2, 0.8) 0%, rgba($color-2, 0.3) 30%, rgba($color-2, 0.3) 70%, rgba($color-2, 0.8) 100%);

        @include MQ(M) {
            padding-top: 130px;
        }

        @include MQ(L) {
            padding-top: 175px;
        }
    }

    .container {
        position: relative;
        height: auto;
        padding-top: 30px;

        @include MQ(S) {
            height: auto;
        }

        @include MQ(L) {
            height: auto;
        }
    }

    .breadcrumb-title {
        color: $color-4;

        a {
            color: $color-4;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }
}

// Galeria
.galeria-fotos {
    clear: both;
    margin: 15px auto;

    .foto {
        width: 100%;
        height: 0;
        padding-bottom: 56.2%;
        background-position: center center;
        background-size: cover;
        margin: 0;
        position: relative;

        a {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}

.projectes {
    margin: 60px auto;

    .owl-stage {
        @include flexbox;
        @include flex-direction($value: row);
        @include align-items($value: stretch);
        padding: 5px 0;
    }

    .projecte {
        width: 100%;
        float: left;
        padding: 0;
        height: 100%;
        background: $color-4;

        .box {
            width: 100%;
            float: left;
            position: relative;
        }

        .cover {
            width: 100%;
            height: 0;
            min-height: 0;
            padding-bottom: 52.6%;
            overflow: hidden;
        }

        .image {
            width: 100%;
            height: 0;
            min-height: 0;
            padding-bottom: 52.6%;
            background-size: cover;
            background-position: center center;
            @include transition(all linear .2s);
        }

        .text {
            color: #111;
            margin: 0 auto;
            text-align: center;
            padding: 15px;
            background-color: $color-4;
            @include transition(all linear .2s);

            h3 {
                margin: 0;
                padding: 0;
                font-size: 16px;
                line-height: 1.3;

                @include MQ(M) {
                    font-size: 20px;
                }
            }
        }

        a {
            display: block;

            &:hover,
            &:focus {
                text-decoration: none;

                .image {
                    @include transform(scale(1.1));
                }

                .text {
                    color: $link;
                }
            }
        }

        &:hover {}
    }
}

.equip {
    margin: 50px auto 0 auto;

    .owl-stage {
        @include flexbox;
        @include flex-direction($value: row);
        @include align-items($value: stretch);
        padding: 0;
    }

    .persona {
        width: 100%;
        float: left;
        padding: 0;
        height: 100%;

        .box {
            width: 100%;
            float: left;
            position: relative;
        }

        .cover {
            width: 100%;
            height: 0;
            min-height: 0;
            padding-bottom: 80%;
            overflow: hidden;
            background: $color-4;
        }

        .image {
            width: 100%;
            height: 0;
            min-height: 0;
            padding-bottom: 80%;
            background-size: cover;
            background-position: center center;
            @include transition(all linear .2s);
        }

        .text {
            color: $main-text;
            margin: 0 auto;
            text-align: left;
            padding: 15px 0;
            @include transition(all linear .2s);

            h3 {
                margin: 0;
                padding: 0;
                font-size: 16px;
                line-height: 1.3;
                font-weight: bold;

                @include MQ(M) {
                    font-size: 20px;
                }
            }

            h4 {
                font-size: 14px;
                margin: 0;
            }
        }

        a {
            display: block;

            &:hover,
            &:focus {
                text-decoration: none;

                .image {
                    @include transform(scale(1.1));
                }

                .text {
                    color: $link;
                }
            }
        }

        &:hover {}
    }
}

// Space
.header-space {
    @include MQ(SM) {
        width: 100%;
        float: left;
        height: 80px;
        background-color: #231F20;
    }
}

// Imatge principal
.img-principal {
    width: 100%;
    float: left;
    height: 300px;
    position: relative;
    background-size: cover;
    background-position: center center;

    @include MQ(SM) {
        height: 0;
        min-height: 0;
        padding-bottom: 30.07752%;
    }

    .img-gran {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: table;
        text-align: center;
        width: 100%;
        height: 100%;
        color: $color-4;

        h2 {
            display: table-cell;
            vertical-align: middle;
            font-family: $text-font;
            font-weight: 900;
            font-size: 50px;
            padding: 0 0 50px 0;

            @include MQ(SM) {
                font-size: 60px;
            }

            @include MQ(M) {
                font-size: 90px;
            }

            @include MQ(L) {
                font-size: 110px;
            }
        }
    }
}

.bg-video-full {
    margin: 0;
    width: 100% !important;
    float: left;
    overflow: hidden;
    height: 0;
    min-height: 0;
    padding-bottom: 50%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    @media(min-width: $screen-sm-min) {
        padding-bottom: 56.25%;
    }

    //position: relative;
    //z-index: 999;
    .video-full {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        display: none;

        @media(min-width: $screen-sm-min) {
            display: block;
        }
    }
}

// imatge videos
.video-img {
    margin: 15px 0;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    min-height: 0;
    width: 100%;
    background-color: #000;

    .play {
        @include center($xy: xy);
        width: 60px;
        height: 60px;
        background-color: transparent;
        background-image: url('../img/play.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        color: $color-4;
        position: absolute;
        text-align: center;
        z-index: 30;
    }

    img {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 20;
        cursor: pointer;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100% !important;
    }
}

// Testimonis
.testimonis {
    .testimoni {
        @include flexbox;
        @include flex-wrap($value: wrap);
        @include align-items($value: center);
        @include align-content($value: center);
        @include justify-content($value: flex-start);

        .image {
            width: 100%;

            @include MQ(SM) {
                width: 50%;
            }

            .cover {
                width: 100%;
                height: 0;
                min-height: 0;
                padding-bottom: 56.2%;
                background-size: cover;
                background-position: center center;
                position: relative;
            }

            .play {
                position: absolute;
                top: 15px;
                left: 15px;
                width: 30px;
                height: 30px;
                background-color: transparent;
                background-image: url('../img/playblack.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                color: $color-4;
                position: absolute;
                text-align: center;
                z-index: 30;
            }
        }

        .text {
            width: 100%;
            padding: 30px;
            position: relative;
            text-align: center;

            @include MQ(SM) {
                width: 50%;
                padding: 0 30px;
            }

            span.fa {
                text-align: center;
                font-size: 30px;
                line-height: 1;
            }

            blockquote {
                margin: 0 auto;
                padding: 0;
                position: relative;
                color: $main-text;
            }

            blockquote:before,
            blockquote:after {
                content: '';
            }
        }
    }
}

.section-title {
    width: 100%;
    float: left;
    text-align: center;
    color: $color-4;
    padding: 15px 0;

    @include MQ(M) {
        padding: 25px 0;
    }

    h3 {
        text-transform: uppercase;
        font-family: $title-font;
        margin: 15px auto;
        font-weight: bold;
        font-size: 18px;

        @include MQ(SM) {
            font-size: 22px;
        }

        @include MQ(M) {
            font-size: 28px;
        }

        @include MQ(L) {
            font-size: 38px;
        }
    }
}

.single-p {
    // Post archive
    padding-top: 60px;

    .post {
        width: 100%;
        float: left;
        margin: 0 0 60px 0;

        //background-color: $bg;
        a {

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        img {
            max-width: 100%;
            height: auto;
        }

        .image {
            margin: 0;
            position: relative;
            width: 100%;
            float: left;

            img {
                width: 100%;
                height: auto;
            }

            .cover {
                height: 0;
                min-height: 0;
                padding-bottom: 56.2%;
                position: relative;
                background-size: cover;
                background-position: center center;
            }

            .fechas {
                position: absolute;
            }
        }

        .text {
            width: 100%;
            float: left;

            .post-title {
                margin: 0 0 30px 0;
            }

            .categories {
                a {
                    margin-right: 10px;
                    font-weight: bold;
                }
            }
        }

        .fecha {
            color: #A6A8AB;
            margin: 10px 0;
            font-family: $title-font;
            @include transition(all linear .1s);
        }

        .embed-responsive {
            margin: 30px auto;
        }
    }

    .compartir {
        margin: 50px 0 15px 0;
        list-style: none;
        text-align: left;
        font-size: 30px;
        width: 100%;
        float: left;

        li {
            display: inline-block;
            margin-right: 10px;
        }
    }
}

// sidebar
.sidebar {
    width: 100%;

    .widget {
        float: left;
        margin: 0 0 15px 0;
        width: 100%;

        h3 {
            text-transform: uppercase;
        }

        ul {
            list-style: none;
            padding: 0;
        }

        .tags-list {
            span {
                display: none;
            }
        }

        .archivo {
            li {
                text-transform: capitalize;
            }
        }
    }
}

// Map
.map-item {
    width: 100%;
    height: 450px;
    float: left;
}

// Forms
span.wpcf7-list-item {
    //margin-left: 0 !important;
    //margin-top: 5px;
    font-size: 12px;
}

// Formulari (necessita js i function)
.formulari {
    margin: 15px auto 60px auto;
    width: 100%;
    float: left;

    .wpcf7-submit {
        float: right;
    }
}

span.wpcf7-list-item {
    margin: 0 5px 10px 0 !important;
    .wpcf7-list-item-label:before {
        padding-left: 5px;
    }
}

.form-group {
    width: 100%;
    float: left;
    margin: 0;

    p {
        display: block;
        float: left;
    }

    label {
        color: $main-text;
        margin: 10px 0;
    }

    :focus {
        outline: none;
    }
}

.form-control {
    @include border-radius(0);
    //color: $gris-clar;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 10px 10px;
    height: auto;
    border: 1px solid rgba(0, 0, 0, 0.075);
    color: $main-text;
    font-size: 16px;
    //@include transition(all linear .2s);
    position: relative;

    &:hover,
    &:focus {
        //border: 1px solid $theme-primary;
        color: $main-text;
        outline: none;
    }

    &:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &::-webkit-input-placeholder {
        //color: $theme-primary;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        //color: $theme-primary;
    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        //color: $theme-primary;
    }

    &:-ms-input-placeholder {
        //color: $theme-primary;
    }
}

textarea {
    @include border-radius(0);
    //color: $gris-clar;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 5px 10px;
    height: auto;
    border: 1px solid rgba(0, 0, 0, 0.075);
    color: $main-text;
    @include transition(all linear .2s);
    width: 100%;
    font-size: 16px;
    position: relative;
    min-height: 200px;

    &:hover,
    &:focus {
        //border: 1px solid $theme-primary;
        color: $main-text;
        outline: none;
    }
}

/*= input focus effects css
=========================== */
:focus {
    outline: none;
}



/* necessary to give position: relative to parent. */
.magia {
    position: relative;
    width: 100%;
    float: left;
    margin-bottom: 15px;
    z-index: 100;
    background: $color-4;
}

.effect-11 {
    border: 1px solid rgba(0, 0, 0, 0.075) !important;
    position: relative !important;
    background: transparent !important;
    z-index: 11 !important;
}

.error .effect-11 {
    border: 1px solid $color-1 !important;
}

.effect-11~.focus-bg {
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    width: 0 !important;
    height: 100% !important;
    background-color: #ececec !important;
    transition: 0.3s !important;
    z-index: 8 !important;
}

.effect-11:focus~.focus-bg {
    transition: 0.3s !important;
    width: 100% !important;
}

div.wpcf7-response-output {
    margin: 0 !important;
    padding: 15px !important;
    border: 0 !important;
}

span.wpcf7-not-valid-tip {
    background-color: transparent !important;
    position: absolute !important;
    top: 0;
    right: 0;
    padding: 10px;
}

// Popup
.popup-block {
    background: transparent;
    color: $color-4;
    padding: 30px 15px 15px 15px;
    text-align: left;
    max-width: 1200px;
    margin: 40px auto;
    position: relative;

    h3 {
        margin-top: 0;
        font-family: $title-font;
        font-weight: bold;
        font-size: 18px;

        @include MQ(SM) {
            font-size: 22px;
        }

        @include MQ(M) {
            font-size: 28px;
        }

        @include MQ(L) {
            font-size: 38px;
        }
    }

    .mfp-close {
        color: #fff !important;
        right: 0px;
        top: -10px;
    }

    .custom-post {
        @include flexbox;
        @include flex-direction($value: row);
        @include flex-wrap($value: wrap);
    }

    .foto {
        width: 100%;

        @include MQ(SM) {
            width: 66.6666666667%;
        }
    }

    .text {
        width: 100%;
        background-color: #483D4A;
        padding: 15px;

        @include MQ(SM) {
            width: 33.333333%;
        }

        .fa {
            width: 20px;
        }

        ul {
            list-style: none;

            li {
                padding: 0;
                margin: 0;
            }
        }
    }
}

.popup-block-post {
    background: #E7ECEC;
    padding: 30px 15px 15px 15px;
    text-align: left;
    max-width: 1200px;
    margin: 40px auto;
    position: relative;

    h3 {
        margin-top: 0;
        font-family: $title-font;
        font-weight: bold;
        font-size: 16px;

        @include MQ(SM) {
            font-size: 18px;
        }

        @include MQ(M) {
            font-size: 22px;
        }

        @include MQ(L) {
            font-size: 28px;
        }
    }

    .mfp-close {
        color: #000 !important;
        right: 0px;
        top: 0;
    }

    .custom-post {
        @include flexbox;
        @include flex-direction($value: row);
        @include flex-wrap($value: wrap);
    }

    .foto {
        width: 100%;

        @include MQ(SM) {
            width: 50%;
        }
    }

    .text {
        width: 100%;
        background-color: transparent;
        padding: 0 15px 0 30px;

        @include MQ(SM) {
            width: 50%;
        }

        .fa {
            width: 20px;
        }

        ul {
            list-style: none;

            li {
                padding: 0;
                margin: 0;
            }
        }
    }
}

// Contacte
.contacte {
    position: relative;
    width: 100%;
    float: left;
    margin: 15px auto;
    @include flexbox;
    @include align-items($value: flex-start);
    @include flex-direction($value: column);
    @include justify-content($value: flex-start);
    @include flex-wrap($value: wrap);

    @include MQ(SM) {
        @include flex-direction($value: row);
    }

    .widget {
        margin: 15px 0;
        width: 100%;
        padding: 15px;

        @include MQ(SM) {
            width: 33.3333%;
        }

        @include MQ(L) {}

        @include MQ(XL) {}

        h3 {
            margin-top: 0;
            color: $color-4;
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 15px;
        }

        p {}

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                a {
                    color: #fff;
                    display: block;
                    font-weight: bold;
                    line-height: 1.2;
                    padding: 5px;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

                    &:hover,
                    &:focus {
                        text-decoration: none;
                        color: $link;
                    }

                    &:before {
                        content: "\f054";
                        font-family: 'FontAwesome';
                        padding-right: 5px;
                        font-size: 8px;
                        line-height: 19px;
                    }
                }

                &:last-child {
                    a {
                        border-bottom: 0;
                    }
                }
            }
        }
    }

    .text-2 {
        @include MQ(L) {
            width: 50%;
        }
    }

    .nav_menu-2 {
        @include MQ(L) {
            width: 25%;
        }
    }

    .nav_menu-3 {
        @include MQ(L) {
            width: 25%;
        }
    }
}

// horarios
.op-table-overview {
    float: left;
    width: 100%;
    overflow: auto;
    margin: 0 0 30px 0;

    tr {
        background-color: #fff;
        border-top: 1px solid #c6cbd1;

        &:nth-child(2n) {
            background-color: #f6f8fa;
        }
    }

    th,
    td {
        padding: 6px 13px;
        border: 1px solid #dfe2e5;
    }

    .op-cell-heading {
        text-transform: uppercase;
    }

    .op-cell-periods {
        span {
            display: inline-block;
            margin: 0 15px;
        }
    }
}

// footer
.prefooter {
    width: 100%;
    float: left;
    background-color: $color-3;
    color: $color-4;
    text-align: left;

    a {
        //color: $color-4;
    }
}

.footer-bg {
    padding: 15px 0;
    color: $color-4;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#422874+0,1b0f39+100 */
    background: $link;

    a {
        color: $color-4;
    }
}

.footer {
    width: 100%;
    float: left;
    margin: 0;
    background-color: $color-2;
    color: $color-4;

    p {
        margin: 15px 0;
        display: inline-block;
        float: right;

        a {
            color: $color-4;
            text-decoration: underline;

            &:hover,
            &:focus {
                text-decoration: underline;
                color: #eee;
            }
        }
    }

    .rights {

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        flex-direction: column;
        @include MQ(L) {
            flex-direction: row;
        }
    }
    .nav {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        > li > a {
            color: #fff;
            &:hover, &:focus {
                color: #fff;
                background-color: transparent;
                text-decoration: underline;
            }
        }

    }

    .social {
        float: right;
        margin: 10px 0 0 0;
        list-style: none;
        display: inline-block;

        li {
            padding: 0 0 0 15px;
            display: inline-block;
            float: left;
            margin: 0;

            a {
                color: $color-4;

                &:hover,
                &:focus {
                    text-decoration: none;
                    color: $theme-secondary;
                }

                span {
                    font-size: 25px;
                    line-height: 40px;
                }
            }
        }
    }
}

.col-md-auto {
    //flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.col {
    //flex-basis: 0;
    //flex-grow: 1;
    position: relative;
    //width: 100%;
    padding-right: 10px;
    padding-left: 10px;
}

.mr-auto {
    margin-right: auto;
}

.error-404 {
    text-align: center;
    width: 100%;
    height: 100vh;
    min-height: 100%;
    @include flexbox;
    @include align-items($value: center);
    background: #e8dcd2 url('../img/404.jpg') center center no-repeat !important;
    background-size: cover !important;
}

.error-404 .message {
    margin: 0 auto 15px auto;
}

.error-404 h1 {
    font-weight: normal;
    font-family: $title-font;
    margin: 0;
    color: $color-4;
    line-height: 1.3;
    font-size: 16px;
    font-weight: bold;
}

@media (min-width:768px) {
    .error-404 h1 {
        font-size: 36px;
    }
}

@media (min-width:1024px) {
    .error4-04 h1 {
        font-size: 42px;
    }
}

.error-404 h1 a {
    color: $theme-primary;
    text-decoration: underline;
}

.error-404 .footer {
    display: none;
}

/* Cookie bar */
#cookie-law-info-bar {
    padding: 0 !important;
    border-top: 0 !important;
    color: #000 !important;
    background: transparent !important;
}

#cookie-law-info-bar p {
    margin: 0;
    display: block;
    clear: both;
    font-weight: normal;
    line-height: 1.3;
    font-size: 10px;

    @media (min-width: $screen-sm-min) {
        font-size: 12px;
    }
}

#cookie-law-info-bar p a {
    color: $theme-primary !important;
}

.cookies {
    position: relative;
    padding: 5px 37px;
    border-left: 3px solid $theme-primary;
    border-top: 3px solid $theme-primary;
    border-right: 3px solid $theme-primary;
    background: #f7f7f7;

    @media (min-width: $screen-sm-min) {
        padding: 20px 0;
    }

    @media (min-width:$screen-md-min) {
        padding: 20px 50px;
    }
}

.cerrar {
    position: absolute;
    right: 10px;
    width: 32px;
    height: 32px;
    margin-top: -16px;
    top: 50%;

    @media (min-width: $screen-sm-min) {
        right: 5px;
    }

    @media (min-width:$screen-md-min) {
        right: 15px;
    }
}

.cerrar a {
    visibility: hidden;
    font-size: 0;
    display: block;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.cerrar a:before {
    visibility: visible;
    font-family: 'ElegantIcons';
    font-size: 30px;
    line-height: 32px;
    display: inline-block;
    content: "\4d";
    color: #000;

    &:hover,
    &:focus {
        color: $theme-primary;
    }
}